<template>
  <v-card flat class="px-8 pb-8">
    <v-card-title style="z-index: 2">
      <span class="headline">Edit page</span>
      <v-spacer/>
    </v-card-title>
    <v-card-subtitle v-if="progress > 0">
      <v-progress-linear v-model="progress"></v-progress-linear>
    </v-card-subtitle>
    <v-card-text class="pa-0">
      <validation-observer ref="creation" v-slot="{ passes }">
        <v-form @submit.prevent="passes(submit)">
          <v-row>
            <v-col cols="7">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0">
                  <v-card>
                    <v-card-title>
                      Page metadata
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="6">
                          <validation-provider name="title meta-tag" rules="required|max:256" v-slot="{ errors }">
                            <v-text-field v-model="form.meta.meta_title" type="text" placeholder="Edit content for title tag"
                                          :error-messages="errors"
                                          counter="256"
                                          label="Title meta-tag"></v-text-field>
                          </validation-provider>
                          <validation-provider name="description meta-tag" rules="required" v-slot="{ errors }">
                            <v-textarea v-model="form.meta.meta_description" placeholder="Edit content for description tag"
                                        :error-messages="errors"
                                        counter
                                        label="Description meta-tag"></v-textarea>
                          </validation-provider>
                        </v-col>
                        <v-col cols="6">
                          <validation-provider name="title OG meta-tag" rules="required|max:256" v-slot="{ errors }">
                            <v-text-field v-model="form.og.og_title" type="text" placeholder="Edit content for OG title tag"
                                          :error-messages="errors"
                                          counter="256"
                                          label="Title OG meta-tag"></v-text-field>
                          </validation-provider>
                          <validation-provider name="URL OG meta-tag"
                                               :rules="{ required: true, max: 256, regex: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/ }" v-slot="{ errors }">
                            <v-text-field v-model="form.og.og_url" type="text" placeholder="Edit content for OG URL tag (https://example.com)"
                                          :error-messages="errors"
                                          pattern="(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]"
                                          counter="256">
                              <template v-slot:label>
                                <div class="d-flex align-center">
                                  <span>URL OG meta-tag</span>
                                </div>
                              </template>
                            </v-text-field>
                          </validation-provider>
                          <validation-provider name="type OG meta-tag" v-slot="{ errors }">
                            <v-text-field v-model="form.og.og_type" type="text" placeholder="Edit content for OG type tag"
                                          :error-messages="errors"
                                          counter="256"
                                          label="Type OG meta-tag"></v-text-field>
                          </validation-provider>
                          <validation-provider name="image URL OG meta-tag"
                                               :rules="{ required: true, max: 256, regex: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/ }" v-slot="{ errors }">
                            <v-text-field v-model="form.og.og_image" type="text" placeholder="Add URL for OG image tag (https://example.com/image.jpeg)"
                                          :error-messages="errors"
                                          pattern="(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]"
                                          counter="256">
                              <template v-slot:label>
                                <div class="d-flex align-center">
                                  <span>Image URL OG meta-tag</span>
                                </div>
                              </template>
                            </v-text-field>
                          </validation-provider>
                          <validation-provider name="description OG meta-tag" rules="required" v-slot="{ errors }">
                            <v-textarea v-model="form.og.og_description" placeholder="Edit content for OG description tag"
                                        :error-messages="errors"
                                        counter
                                        label="Description OG meta-tag"></v-textarea>
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="ma-0 pa-0 mt-8">
                <v-col cols="12" class="ma-0 pa-0">
                  <v-card>
                    <v-card-title>
                      Page slug
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <validation-provider name="title meta-tag" rules="required|max:256" v-slot="{ errors }">
                            <v-text-field v-model="form.slug" type="text" placeholder="Edit content for title tag"
                                          :error-messages="errors"
                                          counter="256"
                                          hint="Page slug is an web address that you would like to see in the search bar of browser"
                                          persistent-hint
                                          label="Page slug"></v-text-field>
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="5">
              <starboxx-tip-component/>
            </v-col>
            <v-col cols="7">
              <v-row class="ma-0 px-0">
                <v-col cols="12" class="ma-0 px-0">
                  <v-card min-height="100">
                    <v-card-title>Content part</v-card-title>
                    <v-card-text>
                      <froala :tag="'textarea'" :config="configuration" v-model="form.main_part"></froala>
                      <template v-if="form.main_part">
                        <label class="font-weight-bold text-h6 my-5 d-block">Main content preview</label>
                        <froalaView v-model="form.main_part"></froalaView>
                      </template>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" class="ma-0 px-0">
                  <v-card min-height="100">
                    <v-card-title>Aside part</v-card-title>
                    <v-card-text>
                      <froala :tag="'textarea'" :config="configuration" v-model="form.aside_part"></froala>
                      <template v-if="form.aside_part">
                        <label class="font-weight-bold text-h6 my-5 d-block">Aside preview</label>
                        <froalaView v-model="form.aside_part"></froalaView>
                      </template>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="ma-0 px-0">
                <v-col cols="12" class="ma-0 px-0">
                  <v-card>
                    <v-card-title>
                      Page Settings
                    </v-card-title>
                    <v-card-text>
                      <div class="d-flex">
                        <div>
                          <v-checkbox v-model="form.settings.is_in_menu" label="Add page to menu?"></v-checkbox>
                        </div>
                        <template v-if="form.settings.is_in_menu">
                          <div class="ml-4 flex-fill">
                            <validation-provider name="menu item title" rules="required|max:256" v-slot="{ errors }">
                              <v-text-field v-model="form.menu.menu_item" type="text" placeholder="Edit menu title"
                                            :error-messages="errors"
                                            counter="256"
                                            label="Menu title"></v-text-field>
                            </validation-provider>
                          </div>
                        </template>
                      </div>
                      <div class="d-flex">
                        <div>
                          <v-checkbox v-model="form.settings.is_slider_on" label="Include slider in page?"></v-checkbox>
                        </div>
                        <template v-if="form.settings.is_slider_on">
                          <div class="ml-4 flex-fill">
                            <validation-provider name="Slider images" rules="max_files:10" v-slot="{ errors }">
                              <v-file-input  v-model="attachments"
                                             @change="chooseFiles"
                                             @click:clear="deleteAttachments"
                                             accept="image/*"
                                             :error-messages="errors"
                                             multiple
                                             hint="* file should not be more than 1Mb"
                                             persistent-hint
                                             show-size
                                             small-chips
                                             prepend-icon="">
                                <template v-slot:prepend-inner>
                                  <v-icon class="mt-2" size="20">mdi-image</v-icon>
                                </template>
                                <template v-slot:selection="{ index, text }">
                                  <v-chip
                                    @click:close="deleteAttachment(index)"
                                    v-if="index < 2"
                                    color="primary accent-4"
                                    dark
                                    label
                                    small
                                    close
                                  >
                                    {{ text }}
                                  </v-chip>

                                  <span
                                    v-else-if="index === 2"
                                    class="overline grey--text text--darken-3 mx-2"
                                  >
                        +{{ attachments.length - 2 }} {{ attachments.length - 2 > 1 ? 'files' : 'file' }}
                      </span>
                                </template>
                              </v-file-input>
                            </validation-provider>
                          </div>
                        </template>
                      </div>
                    </v-card-text>
                  </v-card>
                  <template v-if="form.settings.images.length > 0">
                    <v-card class="mt-8">
                      <v-card-title>Already added slider images</v-card-title>
                      <template v-if="form.settings.is_slider_on">
                        <v-card-text class="d-flex flex-wrap">
                          <template v-for="(item) in form.settings.images">
                            <template v-if="item.hasOwnProperty('id')">
                            <div
                              style="position: relative"
                              class="pa-4"
                              :key="item.id">
                              <v-btn @click="openConfirmationForDeleting(item.id)"
                                     absolute style="right: 6px; top: 3px" fab dark color="danger" width="19" height="19">
                                <v-icon x-small>mdi-close</v-icon>
                              </v-btn>
                              <v-hover v-slot="{ hover }">
                                <v-img
                                  style="transition: .3s ease-in-out" :class="{ 'elevation-2': hover, 'mx-2': true }"
                                  contain max-width="70" :src="item['image_data_url']">
                                </v-img>
                              </v-hover>
                            </div>
                            </template>
                          </template>
                          <div v-for="(item, index) in files"
                               style="position: relative"
                               class="pa-4"
                               :key="index">
                            <v-hover v-slot="{ hover }">
                              <v-img
                                style="transition: .3s ease-in-out" :class="{ 'elevation-2': hover, 'mx-2': true }"
                                contain max-width="70" :src="item">
                              </v-img>
                            </v-hover>
                          </div>
                        </v-card-text>
                      </template>
                    </v-card>
                  </template>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0">
            <v-col cols="12" class="d-flex justify-end">
              <v-btn type="button" class="mr-5" color="warning" :to="{name: 'dashboard.main'}">Cancel</v-btn>
              <v-btn type="submit" color="primary" :disabled="changed < 2">Update page</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </v-card-text>
    <starboxx-confirmation
      :action="{data: id, message: 'Do you really want to delete this slider-image?', name: 'delete-slider-image'}"/>
  </v-card>
</template>

<script>
import UploadService from '@/services/upload.service'
import configuration from '@/config/froala.config'
export default {
  name: 'Edit',
  data () {
    return {
      progress: 0,
      configuration,
      attachments: [],
      search: '',
      loading: false,
      confirmation: false,
      id: null,
      changed: 0,
      form: {
        main_part: null,
        aside_part: null,
        slug: null,
        meta: {
          meta_title: null,
          meta_description: null
        },
        menu: {
          menu_item: null,
          slug: null
        },
        og: {
          og_title: null,
          og_description: null,
          og_url: null,
          og_image: null,
          og_type: null
        },
        settings: {
          is_slider_on: false,
          images: [],
          is_in_menu: false
        }
      },
      files: []
    }
  },
  watch: {
    form: {
      deep: true,
      handler () {
        this.changed++
      }
    }
  },
  beforeMount () {
    this.getPage()
  },
  mounted () {
    this.$root.$on('delete-slider-image', (id) => {
      this.deleteSliderImage(id)
    })
  },
  methods: {
    chooseFiles (files) {
      files.forEach(file => {
        this.form.settings.images.push(file)

        const reader = new FileReader()

        reader.onloadend = () => {
          this.files.push(reader.result)
        }

        if (file) {
          reader.readAsDataURL(file)
        }
      })
    },
    deleteAttachment (index) {
      this.attachments.splice(index, 1)
      this.files.splice(index, 1)
      this.form.settings.images.splice(index, 1)
    },
    deleteAttachments () {
      this.attachments = []
      this.files = []
      this.form.settings.images = []
    },
    submit () {
      UploadService.patchPage(this.form, `${process.env.VUE_APP_API_URL}/api/pages/${this.$route.params.id}?_method=PATCH`, (event) => {
        this.progress = Math.round((100 * event.loaded) / event.total)
      })
        .then(() => {
          this.getPage()
          this.attachments = []
          this.files = []
          this.progress = 0
        })
    },
    getPage () {
      this.$http.get(`${process.env.VUE_APP_API_URL}/api/pages/${this.$route.params.id}`)
        .then(response => {
          this.form = response.data
          if (!this.form.menu) {
            this.form.menu = {
              menu_item: null,
              slug: this.form.slug
            }
          }
        })
    },
    openConfirmationForDeleting (id) {
      this.id = id
      this.$store.dispatch('setConfirmation', true)
    },
    deleteSliderImage (id) {
      this.$http.delete(`${process.env.VUE_APP_API_URL}/api/images/${id}`)
        .then(() => {
          this.getPage()
        })
    }
  }
}
</script>

<style scoped>

</style>
